import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-portifolio',
  templateUrl: './portifolio.component.html',
  styleUrls: ['./portifolio.component.scss']
})
export class PortifolioComponent implements OnInit {

  constructor(private translate: TranslateService,
    private AppComponent: AppComponent) { }

  ngOnInit() {
    this.AppComponent.menuSelected = 'menu2';
  }

}
