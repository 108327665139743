import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-work',
  templateUrl: './work.component.html',
  styleUrls: ['./work.component.scss']
})
export class WorkComponent implements OnInit {

  constructor(private translate: TranslateService,
    private AppComponent: AppComponent) { }

  ngOnInit() {
    this.AppComponent.menuSelected = 'menu3';
  }

}
