import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LottieAnimationViewModule } from 'ng-lottie';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-contato',
  templateUrl: './contato.component.html',
  styleUrls: ['./contato.component.scss']
})
export class ContatoComponent implements OnInit {
  public lottieConfig: Object;
  private anim: any;

  constructor(private translate: TranslateService,
    public TranslateService: TranslateService,
    private AppComponent: AppComponent) {
    LottieAnimationViewModule.forRoot();
    
    this.lottieConfig = {
      path: 'assets/data.json',
      renderer: 'canvas',
      autoplay: true,
      loop: true
    };
   }
  ngOnInit() {
    this.AppComponent.menuSelected = 'menu4';
  }


  //lottie animations
  handleAnimation(anim: any) {
    this.anim = anim;
  }
}
