import { Component, OnInit, AfterViewInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, AfterViewInit {
  menuExpanded = false;
  menuHidden = true; 
  menu:HTMLElement = null;


  constructor(private translate: TranslateService,
        private AppComponent: AppComponent) { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.menu = document.getElementById('menu');
  }

  useLanguage(language: string) {
    this.translate.use(language);
    this.AppComponent.languageSelected = language;

    if(this.menu.getAttribute("aria-hidden")) {
      this.changeMenu();
    }
  }

  CheckMenu(type: string) {
    return this.AppComponent.menuSelected === type;
  }

  CheckLanguage(type: string) {
    return this.AppComponent.languageSelected === type;
  }

  changeMenu(){
    this.menuExpanded = !this.menuExpanded;
    this.menuHidden = !this.menuHidden;
    
    this.menu.setAttribute("aria-expanded", String(this.menuExpanded));
    this.menu.setAttribute("aria-hidden", String(this.menuHidden));
  }

  

}
