import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})

export class AppComponent {
  public menuSelected = 'menu1';
  public languageSelected;

  constructor(public translate: TranslateService) {
    translate.setDefaultLang('pt');
    this.languageSelected = 'pt';
   }

   public useLanguage(language: string) {
    this.translate.use(language);
  }
}
