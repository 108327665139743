import { Component, OnInit, AfterViewInit } from '@angular/core';
import { LottieAnimationViewModule } from 'ng-lottie';
import { TranslateService } from '@ngx-translate/core';
import Typed from 'typed.js';
import { AppComponent } from '../app.component';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, AfterViewInit {
  public lottieConfig: Object;
  private anim: any;
  private animationSpeed: number = 1;
  public frase1: String;
 

  constructor(private translate: TranslateService,
    public TranslateService: TranslateService,
    private AppComponent: AppComponent) {
    this.AppComponent.menuSelected = 'menu1';
    LottieAnimationViewModule.forRoot();
    this.translate.stream('TESTE2').subscribe((value) => { 
      this.frase1 = value;
    });
    
    this.lottieConfig = {
      path: 'assets/data.json',
      renderer: 'canvas',
      autoplay: true,
      loop: true
    };
   }

  ngOnInit() {
  
  }

  ngAfterViewInit() {
    var options = {
      strings: [  'AI' , 'RPA', 'Machine Learning' , 'Big Data', 'Analytics'],
      typeSpeed: 30,
      backSpeed: 30,
      showCursor: true,
      cursorChar: '|',
      loop: true
    };
    var typed = new Typed('.typed', options);
  }
  

   //lottie animations
   handleAnimation(anim: any) {
    this.anim = anim;
  }

  stop() {
      this.anim.stop();
  }

  play() {
      this.anim.play();
  }

  pause() {
      this.anim.pause();
  }

  setSpeed(speed: number) {
      this.animationSpeed = speed;
      this.anim.setSpeed(speed);
  }

  useLanguage(language: string) {
    this.translate.use(language);
  }
}
